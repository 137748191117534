<template>
    <div class="home3_section_container">
        <div class="home3_section_title_group">
            <h1 class="home3_section_title wow animate__animated animate__aplayzFadeInUp  animate__fast" data-wow-offset="30">
                <slot name="home3sectiontitle">
                    세상에 없던
                    <div class="home3_section_title_br"></div>
                    AI 공간 음악 서비스
                </slot>
            </h1>
            <p
                class="home3_section_paragraph wow animate__animated animate__aplayzFadeInUp  animate__fast"
                data-wow-offset="35"
            >
                <slot name="home3sectionparagraph">
                    다양한 공간, 상황, 목적, 브랜드 등의 다양한 수집 데이터를 <br />
                    AI Curation Engine으로 음원과 매칭하여 실시간으로 추천합니다.
                </slot>
            </p>
            <p
                class="home3_section_paragraph_mobile wow animate__animated animate__aplayzFadeInUp  animate__fast"
                data-wow-offset="35"
            >
                <slot name="home3sectionparagraphmobile">
                    다양한 공간, 상황, 목적, 브랜드 등의 <br />
                    다양한 수집 데이터를 AI Curation Engine으로 <br />
                    음원과 매칭하여 실시간으로 추천합니다.
                </slot>
            </p>
        </div>
        <!-- ################### PC Start  ################### -->
        <div class="home3_section_contents_pc">
            <div
                class="home3_section_contents wow animate__animated animate__aplayzFadeInUp  animate__fast"
                data-wow-offset="40"
            >
                <!-- PC -->
                <slot name="sectioncontents1">
                    <img class="w_100" src="/media/images/home/section_img1pc_ko.png" alt="공간데이터/음악데이터" />
                </slot>
            </div>
            <ul
                class="home3_section_tab dp_flex wow animate__animated animate__aplayzFadeInUp  animate__fast"
                :class="{ en: isen }"
                data-wow-offset="45"
            >
                <li>
                    <a
                        class="home3_section_tab_btn cursor"
                        @click="isActiveTab = 'tab1'"
                        :class="{ active: isActiveTab === 'tab1' }"
                    >
                        <slot name="home3sectiontab1">
                            공간 데이터
                        </slot>
                    </a>
                </li>
                <li class="home3_section_tab2">
                    <a
                        class="home3_section_tab_btn cursor"
                        @click="isActiveTab = 'tab2'"
                        :class="{ active: isActiveTab === 'tab2' }"
                    >
                        <slot name="home3sectiontab2">
                            음악 데이터
                        </slot>
                    </a>
                </li>
            </ul>
            <div class="tab1_contents" v-show="isActiveTab === 'tab1'">
                <p class="tab_cotents_text wow animate__animated animate__aplayzFadeInUp  animate__fast" data-wow-offset="50">
                    <slot name="tab_cotents_text1">
                        H/W 연동을 통한 실시간 데이터 수집, 실시간 외부 데이터 수집, 기본 매장 정보를 통해 <br />
                        데이터가 수집되며, 수집된 데이터는 분석/가공을 거쳐 활용됩니다.
                    </slot>
                </p>

                <ul class="subtab dp_flex wow animate__animated animate__aplayzFadeInUp  animate__fast" data-wow-offset="55">
                    <li class="tab1_sub1tab">
                        <div class="w_100 align_center">
                            <div class="dotted_wrap">
                                <div class="dotted_circle"></div>
                                <div class="dotted_line"></div>
                            </div>

                            <span class="tab_sub1tab_headtext">
                                <slot name="tab_sub1tab_headtext1">
                                    데이터 수집
                                </slot>
                            </span>
                            <div class="dotted_wrap">
                                <div class="dotted_line"></div>
                                <div class="dotted_circle"></div>
                            </div>
                        </div>
                        <div class="w_100 tab1_sub1tab_contents">
                            <img
                                class="tab1_sub1tab_dimimg"
                                src="/media/images/home/tab1_sub1tab_dim_img.png"
                                alt="tab1_sub1tab_dim_img.png"
                            />
                            <img class="tab1_sub1tab_img1" src="/media/images/home/tab1_sub1tab_img.png" alt="tab1_sub1tab_img.png" />
                            <slot name="sub1tabinfogroupimg">
                                <img
                                    class="tab1_sub1tab_infogroup"
                                    src="/media/images/home/tab1_sub1tab_infogroup.png"
                                    alt="tab1_sub1tab_infogroup"
                                />
                            </slot>
                        </div>
                    </li>
                    <li>
                        <img class="next_arrow" src="/media/images/home/next_arrow.png" alt="next_arrow.png" />
                    </li>
                    <li class="tab1_sub2tab">
                        <div class="w_100 align_center">
                            <div class="dotted_wrap sub2tab">
                                <div class="dotted_circle"></div>
                                <div class="dotted_line"></div>
                            </div>

                            <slot name="tab_sub1tab_headtext2">
                                <span class="tab_sub1tab_headtext">
                                    데이터 분석/가공
                                </span>
                            </slot>
                            <div class="dotted_wrap sub2tab">
                                <div class="dotted_line"></div>
                                <div class="dotted_circle"></div>
                            </div>
                        </div>
                        <ul class="dp_flex tab1_sub2tab_list flex_column">
                            <slot name="tab1_sub2tab_item1">
                                <li class="tab1_sub2tab_item flex_justify_center">
                                    연령, 성별, 혼잡도
                                </li>
                            </slot>
                            <slot name="tab1_sub2tab_item2">
                                <li class="tab1_sub2tab_item flex_justify_center">
                                    장르, 시대, 피드백
                                </li>
                            </slot>

                            <slot name="tab1_sub2tab_item3">
                                <li class="tab1_sub2tab_item flex_justify_center">
                                    날씨, 시간, 계절
                                </li>
                            </slot>

                            <slot name="tab1_sub2tab_item4">
                                <li class="tab1_sub2tab_item last flex_justify_center">
                                    업종, 분위기, 방문자
                                </li>
                            </slot>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="tab2_contents" v-show="isActiveTab === 'tab2'">
                <p class="tab_cotents_text">
                    <slot name="tab_cotents_text2">
                        음원들은 자동 라벨링 시스템을 거쳐 공간 데이터 관점으로 라벨링되어 <br />
                        해당 데이터 기반으로 공간별 최적의 음원이 추천됩니다.
                    </slot>
                </p>
                <ul class="subtab dp_flex">
                    <li class="tab2_sub1tab" :class="{ en: isen }">
                        <div class="w_100 align_center">
                            <div class="dotted_wrap tab2sub1" :class="{ en: isen }">
                                <div class="dotted_circle"></div>
                                <div class="dotted_line"></div>
                            </div>

                            <span class="tab_sub1tab_headtext">
                                <slot name="tab_sub1tab_headtext1">
                                    데이터 수집
                                </slot>
                            </span>
                            <div class="dotted_wrap tab2sub1" :class="{ en: isen }">
                                <div class="dotted_line"></div>
                                <div class="dotted_circle"></div>
                            </div>
                        </div>
                        <slot name="tab2subtab2contentsimg1">
                            <figure class="tab2_subtab2_contents_wrap flex_justify_center">
                                <img
                                    class="tab2_subtab2_contents_img"
                                    src="/media/images/home/tab2_subtab2_contents.png"
                                    alt="tab2_subtab2_contents.png"
                                />
                            </figure>
                        </slot>
                    </li>
                    <li>
                        <figure class="next_arrow_wrap">
                            <img class="next_arrow" src="/media/images/home/next_arrow.png" alt="next_arrow.png" />
                        </figure>
                    </li>
                    <li class="tab2_sub2tab">
                        <div class="w_100 align_center">
                            <div class="dotted_wrap tab2sub2" :class="{ en: isen }">
                                <div class="dotted_circle"></div>
                                <div class="dotted_line"></div>
                            </div>
                            <slot name="tab_sub1tab_headtext2">
                                <span class="tab_sub1tab_headtext">데이터 분석/가공</span>
                            </slot>

                            <div class="dotted_wrap tab2sub2" :class="{ en: isen }">
                                <div class="dotted_line"></div>
                                <div class="dotted_circle"></div>
                            </div>
                        </div>
                        <ul class="tab2_sub2tab_list dp_flex">
                            <slot name="tab2_sub2tab_item1">
                                <li class="tab2_sub2tab_item flex_column flex_justify_center" :class="{ en: isen }">
                                    <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text1" :class="{ en: isen }">분위기</span>
                                    <p class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">
                                        모던 7<br />
                                        럭셔리 3<br />
                                        빈티지 3
                                    </p>
                                    <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">...</span>
                                </li>
                            </slot>

                            <slot name="tab2_sub2tab_item2">
                                <li class="tab2_sub2tab_item flex_column flex_justify_center mlr_auto">
                                    <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text1" :class="{ en: isen }">시간</span>
                                    <p class="tab2_sub2tab_item_text tab2_sub2tab_item_text2" :class="{ en: isen }">
                                        오전 5<br />
                                        오후 6<br />
                                        새벽 3
                                    </p>
                                    <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">...</span>
                                </li>
                            </slot>

                            <slot name="tab2_sub2tab_item3">
                                <li class="tab2_sub2tab_item flex_column flex_justify_center  mr_auto">
                                    <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text1" :class="{ en: isen }">계절</span>
                                    <p class="tab2_sub2tab_item_text tab2_sub2tab_item_text2" :class="{ en: isen }">
                                        봄 5<br />
                                        여름 3<br />
                                        가을 3
                                    </p>
                                    <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">...</span>
                                </li>
                            </slot>

                            <slot name="tab2_sub2tab_item4">
                                <li class="tab2_sub2tab_item flex_column flex_justify_center">
                                    <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text1" :class="{ en: isen }">감정</span>
                                    <p class="tab2_sub2tab_item_text tab2_sub2tab_item_text2" :class="{ en: isen }">
                                        기쁨 5<br />
                                        평온 3<br />
                                        슬픔 3
                                    </p>
                                    <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">...</span>
                                </li>
                            </slot>
                        </ul>
                        <p class="tab2_sub2tab_text_pc">
                            <slot name="tab2_sub2tab_text1">
                                음원 자동 라벨링 기술
                            </slot>
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <!-- ################### PC End  ################### -->

        <!-- ################### Tablet Start ###################-->
        <div class="home3_section_contents_tablet">
            <div
                class="home3_section_contents flex_column wow animate__animated animate__aplayzFadeInUp  animate__fast"
                data-wow-offset="40"
            >
                <!-- Tablet -->
                <slot name="sectioncontents1tablet">
                    <img
                        class="home3_section_contents_tablet_img w_100"
                        src="/media/images/home/section_img1tablet_ko.png"
                        alt="공간데이터/음악데이터"
                    />
                </slot>
                <!-- Mobile -->
                <slot name="sectioncontents1mobile">
                    <img
                        class="home3_section_contents_mobile_img w_100"
                        src="/media/images/home/section_img1mobile_ko.png"
                        alt="공간데이터/음악데이터"
                    />
                </slot>
            </div>
            <ul
                class="home3_section_tab dp_flex wow animate__animated animate__aplayzFadeInUp  animate__fast"
                :class="{ en: isen }"
                data-wow-offset="45"
            >
                <li>
                    <a
                        class="home3_section_tab_btn cursor"
                        @click="isActiveTab = 'tab1'"
                        :class="{ active: isActiveTab === 'tab1' }"
                    >
                        <slot name="home3sectiontab1">
                            공간 데이터
                        </slot>
                    </a>
                </li>
                <li class="home3_section_tab2">
                    <a
                        class="home3_section_tab_btn cursor"
                        @click="isActiveTab = 'tab2'"
                        :class="{ active: isActiveTab === 'tab2' }"
                    >
                        <slot name="home3sectiontab2">
                            공간 데이터
                        </slot>
                    </a>
                </li>
            </ul>
            <div
                class="tab1_contents wow animate__animated animate__aplayzFadeInUp  animate__fast"
                data-wow-offset="55"
                v-show="isActiveTab === 'tab1'"
            >
                <p class="tab_cotents_text">
                    <slot name="tab_cotents_text1_tablet">
                        H/W 연동을 통한 실시간 데이터 수집, 실시간 외부 데이터 수집,<br />
                        기본 매장 정보를 통해 데이터가 수집되며, 수집된 데이터는<br />
                        분석/가공을 거쳐 활용됩니다.
                    </slot>
                </p>
                <p class="tab_cotents_text_mobile">
                    <slot name="tab_cotents_text1_mobile">
                        H/W 연동을 통한 실시간 데이터 수집,<br />
                        실시간 외부 데이터 수집, 기본 매장 정보를 통해 <br />
                        데이터가 수집되며, 수집된 데이터는 <br />
                        분석/가공을 거쳐 활용됩니다.
                    </slot>
                </p>

                <ul class="subtab flex_column">
                    <li class="tab1_sub1tab">
                        <div class="w_100 align_center">
                            <div class="dotted_wrap">
                                <div class="dotted_circle"></div>
                                <div class="dotted_line"></div>
                            </div>

                            <span class="tab_sub1tab_headtext">
                                <slot name="tab_sub1tab_headtext1">
                                    데이터 수집
                                </slot>
                            </span>
                            <div class="dotted_wrap">
                                <div class="dotted_line"></div>
                                <div class="dotted_circle"></div>
                            </div>
                        </div>
                        <div class="w_100 tab1_sub1tab_contents">
                            <div class="tab1_sub1tab_img1_tabletwrap">
                                <img
                                    class="tab1_sub1tab_img1_tablet"
                                    src="/media/images/home/tab1_sub1tab_img_tablet.png"
                                    alt="tab1_sub1tab_img_tablet"
                                />
                                <slot name="sub1tabinfogroupimgtablet">
                                    <img
                                        class="tab1_sub1tab_infogroup"
                                        src="/media/images/home/tab1_sub1tab_infogroup_tablet.png"
                                        alt="tab1_sub1tab_infogroup"
                                    />
                                </slot>
                            </div>
                        </div>
                        <div class="tab1_sub1tab_arrow_wrap align_center flex_justify_center">
                            <img class="next_arrow next_arrow_rotate" src="/media/images/home/next_arrow.png" alt="next_arrow.png" />
                        </div>
                    </li>
                    <li class="tab1_sub2tab">
                        <div class="w_100 align_center">
                            <div class="dotted_wrap sub2tab" :class="{ en: isen }">
                                <div class="dotted_circle"></div>
                                <div class="dotted_line"></div>
                            </div>
                            <slot name="tab_sub1tab_headtext2">
                                <span class="tab_sub1tab_headtext">데이터 분석/가공</span>
                            </slot>

                            <div class="dotted_wrap sub2tab" :class="{ en: isen }">
                                <div class="dotted_line"></div>
                                <div class="dotted_circle"></div>
                            </div>
                        </div>

                        <ul class="dp_flex tab1_sub2tab_list flex_column">
                            <slot name="tab1_sub2tab_item1">
                                <li class="tab1_sub2tab_item flex_justify_center">
                                    연령, 성별, 혼잡도
                                </li>
                            </slot>

                            <slot name="tab1_sub2tab_item2">
                                <li class="tab1_sub2tab_item flex_justify_center">
                                    장르, 시대, 피드백
                                </li>
                            </slot>

                            <slot name="tab1_sub2tab_item3">
                                <li class="tab1_sub2tab_item flex_justify_center">
                                    날씨, 시간, 계절
                                </li>
                            </slot>

                            <slot name="tab1_sub2tab_item4">
                                <li class="tab1_sub2tab_item flex_justify_center">
                                    업종, 분위기, 방문자
                                </li>
                            </slot>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="tab2_contents" v-show="isActiveTab === 'tab2'">
                <p class="tab_cotents_text">
                    <slot name="tab_cotents_text2_tablet">
                        음원들은 자동 라벨링 시스템을 거쳐 공간 데이터 관점으로 라벨링되어 <br />
                        해당 데이터 기반으로 공간별 최적의 음원이 추천됩니다.
                    </slot>
                </p>
                <p class="tab_cotents_text_mobile">
                    <slot name="tab_cotents_text2_mobile">
                        음원들은 자동 라벨링 시스템을 거쳐 공간 데이터 관점으로 라벨링되어 해당 데이터 기반으로 공간별 최적의 음원이
                        추천됩니다.
                    </slot>
                </p>

                <ul class="subtab flex_column">
                    <li class="tab2_sub1tab">
                        <div class="w_100 align_center">
                            <div class="dotted_wrap tab2sub1">
                                <div class="dotted_circle"></div>
                                <div class="dotted_line"></div>
                            </div>

                            <slot name="tab_sub1tab_headtext1">
                                <span class="tab_sub1tab_headtext">데이터 수집</span>
                            </slot>

                            <div class="dotted_wrap tab2sub1">
                                <div class="dotted_line"></div>
                                <div class="dotted_circle"></div>
                            </div>
                        </div>
                        <div class="w_100 tab2_sub1tab_contents">
                            <slot name="tab2sub1tabtabletimg1">
                                <div class="tab2_sub1tab_img1_tabletwrap">
                                    <img
                                        class="tab2_sub1tab_img1_tablet"
                                        src="/media/images/home/tab2_subtab2_contents.png"
                                        alt="tab1_sub1tab_img_tablet"
                                    />
                                </div>
                            </slot>
                        </div>
                        <div class="tab1_sub1tab_arrow_wrap tab2_sub1tab_arrow_wrap align_center flex_justify_center">
                            <img class="next_arrow next_arrow_rotate" src="/media/images/home/next_arrow.png" alt="next_arrow.png" />
                        </div>
                    </li>
                    <li class="tab2_sub2tab">
                        <div class="w_100 align_center">
                            <div class="dotted_wrap tab2sub2" :class="{ en: isen }">
                                <div class="dotted_circle"></div>
                                <div class="dotted_line"></div>
                            </div>

                            <slot name="tab_sub1tab_headtext2">
                                <span class="tab_sub1tab_headtext">데이터 분석/가공</span>
                            </slot>

                            <div class="dotted_wrap tab2sub2" :class="{ en: isen }">
                                <div class="dotted_line"></div>
                                <div class="dotted_circle"></div>
                            </div>
                        </div>
                        <ul class="tab2_sub2tab_list dp_flex">
                            <slot name="tab2_sub2tab_item1">
                                <li class="tab2_sub2tab_item flex_column flex_justify_center">
                                    <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text1">분위기</span>
                                    <p class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">
                                        모던 7<br />
                                        럭셔리 3<br />
                                        빈티지 3
                                    </p>
                                    <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">...</span>
                                </li>
                            </slot>
                            <slot name="tab2_sub2tab_item2">
                                <li class="tab2_sub2tab_item flex_column flex_justify_center mlr_auto">
                                    <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text1">시간</span>
                                    <p class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">
                                        오전 5<br />
                                        오후 6<br />
                                        새벽 3
                                    </p>
                                    <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">...</span>
                                </li>
                            </slot>
                            <slot name="tab2_sub2tab_item3">
                                <li class="tab2_sub2tab_item flex_column flex_justify_center  mr_auto">
                                    <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text1">계절</span>
                                    <p class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">
                                        봄 5<br />
                                        여름 3<br />
                                        가을 3
                                    </p>
                                    <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">...</span>
                                </li>
                            </slot>
                            <slot name="tab2_sub2tab_item4">
                                <li class="tab2_sub2tab_item flex_column flex_justify_center">
                                    <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text1">감정</span>
                                    <p class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">
                                        기쁨 5<br />
                                        평온 3<br />
                                        슬픔 3
                                    </p>
                                    <span class="tab2_sub2tab_item_text tab2_sub2tab_item_text2">...</span>
                                </li>
                            </slot>
                        </ul>
                        <p class="tab2_sub2tab_text">
                            <slot name="tab2_sub2tab_text1_tablet">
                                음원 자동 라벨링 기술
                            </slot>
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    <!-- ################### Tablet End ###################-->
    </div>
</template>
<script>
import { WOW } from 'wowjs';
export default {
  name: 'HomeSection3',
  props: {
    isen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isActiveTab: 'tab1',
      wow: null
    };
  },

  mounted () {
    this.wow = new WOW({ live: false });
    this.wow.init();
    this.wow.sync();
  },

  methods: {}
};
</script>
<style src="@/assets/css/home/homesection3.css" scoped></style>
